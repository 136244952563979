//
// _card.scss
//

.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: $box-shadow;
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;
}

.profile-name-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  & .change-number {
    max-height: 50px;
    margin-top: 12px;
  }

  @media (min-width: 600px) {
    flex-direction: row;
  }
}
